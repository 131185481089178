.filter-select {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: .5rem;
    margin-block: 10px;
}

.filter-option {
    display: flex;
    background: rgb(226, 180, 180);
    border-radius: 5px;
    padding: 5px;
}

.filter-option input {
    --_c: 1rem;
    width: var(--_c);
    height: var(--_c);
    accent-color: rgb(40, 116, 230);
}