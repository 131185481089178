.memories-container {
    all: unset;
    margin-block: 2rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
}

.memory-item {
    all: unset;
    border-radius: 15px;
}

.memory-item > div {
    padding-bottom: .5rem;
}

.memory-header-container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.highlight {
    background-color: yellow;
}