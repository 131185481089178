:root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
    overscroll-behavior: none;
}

html,
body,
#root,
#root > .app-container {
    height: 100%;
}

::-webkit-scrollbar {
    width: 0.4rem;
}
::-webkit-scrollbar-thumb {
    border-radius: 12px;
    visibility: 'hidden';
}


/* Style pour adaptive card */

.ac-container.ac-adaptiveCard, 
.ac-container.ac-adaptiveCard * {
    font-family: Arial !important;
}


.ac-media-poster {}

.ac-media-poster.empty {
    height: 200px;
    background-color: #F2F2F2;
}

.ac-media-playButton {
    width: 56px;
    height: 56px;
    border: 1px solid #EEEEEE;
    border-radius: 28px;
    box-shadow: 0px 0px 10px #EEEEEE;
    background-color: rgba(255, 255, 255, 0.9);
    color: black;
    cursor: pointer;
}

.ac-media-playButton-arrow {
    color: black;
}

.ac-media-playButton:hover {
    background-color: white;
}

.ac-image.ac-selectable {
    cursor: pointer;
}

.ac-image.ac-selectable:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.ac-image.ac-selectable:active {
    background-color: rgba(0, 0, 0, 0.15);
}

a.ac-anchor {
    text-decoration: none;
}

a.ac-anchor:link {
    color: #005a9e;
}

a.ac-anchor:visited {
    color: #005a9e;
}

a.ac-anchor:link:active {
    color: #004d84;
}

a.ac-anchor:visited:active {
    color: #004d84;
}

.ac-container.ac-selectable, .ac-columnSet.ac-selectable {
    padding: 0px;
}

.ac-container.ac-selectable:hover, .ac-columnSet.ac-selectable:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.ac-container.ac-selectable:active, .ac-columnSet.ac-selectable:active {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

.ac-pushButton {
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    font-family: "Calibri", sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #DDDDDD;
    background-color: #FFFFFF;
    color: #0063B1;
}

.ac-pushButton:hover,
.ac-pushButton:focus {
    border-color: #0063B1;
}

.ac-pushButton.style-positive {
    background-color: #0072CC;
    color: white;
}

.ac-pushButton.style-positive:hover, .ac-pushButton.style-positive:active {
    background-color: #006ABC;
}

.ac-pushButton.style-destructive {
    background-color: #E50000;
    color: white;
}

.ac-pushButton.style-destructive:hover, .ac-pushButton.style-destructive:active {
    background-color: #BF0000;
}

.ac-pushButton-disabled {
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    font-family: "Calibri", sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #EEEEEE;
    background-color: #EEEEEE;
    color: #666666;
}

.ac-quickActionButton {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    font-family: "Calibri", sans-serif;
    font-size: 14px;
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    background-color: #DDDDDD;
}

.ac-input {
    font-family: "Calibri", sans-serif;
    font-size: 14px;
    color: black;
}

.ac-input.ac-input-required {
}

.ac-input.ac-textInput.ac-input-validation-failed,
.ac-input.ac-numberInput.ac-input-validation-failed,
.ac-input.ac-dateInput.ac-input-validation-failed,
.ac-input.ac-timeInput.ac-input-validation-failed,
.ac-input.ac-multichoiceInput.ac-choiceSetInput-compact.ac-input-validation-failed {
    border: 1px solid red !important;
}

.ac-input.ac-toggleInput.ac-input-validation-failed,
.ac-input.ac-choiceSetInput-expanded.ac-input-validation-failed,
.ac-input.ac-choiceSetInput-multiSelect.ac-input-validation-failed {
    outline: 1px solid red;
    outline-offset: 1px;
}

.ac-input.ac-choiceSetInput-multiSelect {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.ac-input.ac-choiceSetInput-multiSelect input {
    height: 17px;
    width: 17px;
    accent-color: #0072CC;
}

.ac-input-container {
    display: flex;
    align-items: flex-end;
}

.ac-input.ac-textInput {
    resize: none;
}

.ac-input.ac-textInput.ac-multiline {
    height: 72px;
}

.ac-input.ac-textInput, .ac-input.ac-numberInput, .ac-input.ac-dateInput, .ac-input.ac-timeInput, .ac-input.ac-multichoiceInput {
    border: 1px solid #dddddd;
    padding: 4px 8px 4px 8px;
    height: 31px;
}

.ac-input.ac-multichoiceInput option {
    font-size: 16px;
}

/* ac-inlineActionButton should set height to the same as ac-input.ac-textInput */

.ac-inlineActionButton {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    font-family: "Calibri", sans-serif;
    font-size: 13.3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    background-color: transparent;
    height: 31px;
}

.ac-inlineActionButton.textOnly {
    padding: 0 8px;
}

.ac-inlineActionButton.iconOnly {
    padding: 0;
}

.ac-inlineActionButton:hover {
    background-color: #EEEEEE;
}

.ac-inlineActionButton:active {
    background-color: #CCCCCC;
}

.ac-inlineActionButton-disabled {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    font-family: "Calibri", sans-serif;
    font-size: 13.3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    background-color: transparent;
    height: 31px;
}

.ac-inlineActionButton-disabled.textOnly {
    padding: 0 8px;
}

.ac-inlineActionButton-disabled.iconOnly {
    padding: 0;
}

.webChatOuterContainer {
    background-color: #eceff1;
    border: 1px solid #e6e6e6;
    padding: 8px;
    width: 416px;
    border-radius: 4px;
}

@media (max-width: 416px) {
    .webChatOuterContainer {
        width: 100%;
    }
}

/* Popup menu */

.ac-ctrl-overlay {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10000;
}
  
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
}
  
@keyframes slideTopToBottom {
    0% {
        transform: translate(0, -20px);
    }
}
  
@keyframes slideBottomToTop {
    0% {
        transform: translate(0, 20px);
    }
}
  
@keyframes slideLeftToRight {
    0% {
        transform: translate(-20px, 0);
    }
}
  
@keyframes slideRightToLeft {
    0% {
        transform: translate(20px, 0);
    }
}
  
.ac-ctrl-slide {
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
}
  
.ac-ctrl-slide.ac-ctrl-slideTopToBottom {
    animation-name: fadeIn, slideTopToBottom;
}
  
.ac-ctrl-slide.ac-ctrl-slideBottomToTop {
    animation-name: fadeIn, slideBottomToTop;
}
  
.ac-ctrl-slide.ac-ctrl-slideLeftToRight {
    animation-name: fadeIn, slideLeftToRight;
}
  
.ac-ctrl-slide.ac-ctrl-slideRightToLeft {
    animation-name: fadeIn, slideRightToLeft;
}
  
.ac-ctrl {
    box-sizing: border-box;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: normal;
}
  
.ac-ctrl-dropdown-item {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    padding: 0px 12px 0px 12px;
    line-height: 30px;
    cursor: pointer;
}
  
.ac-ctrl-dropdown-item-disabled {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    padding: 0px 12px 0px 12px;
    line-height: 30px;
    cursor: pointer;
    color: #666666;
}
  
.ac-ctrl-dropdown-item:hover {
    background-color: #EEEEEE;
}
  
.ac-ctrl-dropdown-item:focus {
    outline: 0;
    background-color: #CCCCCC;
}
  
.ac-ctrl-popup-container {
    border: 1px solid #EEEEEE;
    background-color: white;
    position: absolute;
    box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.4);
    overflow-y: auto;
}
  
.ac-ctrl-popup-container:focus {
    outline: 0;
}